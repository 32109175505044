<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>补色添加编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div>
                        <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                    </div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form label-width="100px" ref="info" :model="info">

                    <el-form-item label="姓名" prop="F_VR_XINGM" :rules="[{required:true,message:'姓名不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入姓名" v-model="info.F_VR_XINGM"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="F_VR_SHOUJH" :rules="[{required:true,message:'手机号码不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入手机号码" v-model="info.F_VR_SHOUJH"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="F_TI_SHIFQX" :rules="[{required:true,message:'状态不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_TI_SHIFQX" placeholder="请选择状态">
                            <el-option label="正常" value="1"></el-option>
                            <el-option label="取消" value="2"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="门店地址" prop="F_VR_MENDDZ" :rules="[{required:true,message:'门店地址不能为空',trigger: 'blur' }]">
                        <el-select v-model="info.F_VR_MENDDZ" placeholder="请选择门店地址" @change="selectChange()">
                            <el-option v-for="(v,k) in info['mendlist']" :label="v.F_VR_MINGC" :key="k" :value="v.F_VR_MINGC"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="日期" prop="F_VR_RIQ" >
                        <el-date-picker
                                v-model="info.F_VR_RIQ"
                                type="date"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"

                                :picker-options="pickerOptionsEnd"
                                placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>

                   <el-form-item label="时间点" prop="F_VR_SHIJ" >
                        <el-select v-model="info.F_VR_SHIJ" placeholder="请选择时间点" @change="selectChange()">
                            <el-option v-for="(v,k) in shij" :label="v" :key="k" :value="v"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                edit_id: 0,
                shij:['10:30', '13:30', '16:30'],
                list: [],
                info:{},
                upload_headers: {},

                startTime: '',//开始日期
                endTime: '',//结束日期
                pickerOptionsStart:{
                    disabledDate:(time) => {
                        let state
                        if (this.endTime!=="") {
                            state = time.getTime() > Date.now() || time.getTime() > new Date(this.endTime).getTime()
                        }else {
                            state = time.getTime() > Date.now()
                        }
                        return state
                    },
                },//开始日期配置
                pickerOptionsEnd:{
                    disabledDate:(time)=> {
                        return time.getTime() <  time.getTime() > Date.now()
                        // return   time.getTime() > Date.now()
                    },
                },//结束日期配置

            };
        },
        watch: {},
        computed: {},
        methods: {

            resetForm: function (e) {
                this.$refs[e].resetFields();
            },
            submitForm: function (e) {
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function (res) {
                    if (res) {
                        //  判断是Add 或者 Edit
                        let url = _this.$api.addXianxddbs;
                        if (_this.edit_id > 0) {
                            url = _this.$api.editXianxddbs + _this.edit_id;
                        }
                        delete _this.info['mendlist'];
                        // Http 请求
                        _this.$post(url, _this.info).then(function (res) {
                            if (res.code == 200) {
                                _this.$message.success("编辑成功");
                                _this.$router.go(-1);
                            } else {
                                _this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_adv_info: function () {
                let _this = this;
                this.$get(this.$api.editXianxddbs + this.edit_id).then(function (res) {
                    _this.info = res.data;
                    console.log(res.data)
                    _this.list = res.data.gzhgz;
                })

            },
            get_adv_list: function () {
                let _this = this;
                this.$get(this.$api.addXianxddbs).then(function (res) {
                    _this.info = res.data;
                })

            },
            selectChange: function () {
                this.$forceUpdate();
            },
            handleAvatarSuccess(res) {
                this.info.F_VR_TOUX = res.data;
                this.$forceUpdate();
            },
            handleAvatarSuccesses(res) {
                this.info.F_VR_WEIXEWM = res.data;
                this.$forceUpdate();
            },
        },
        created() {
            this.upload_headers.Authorization = 'Bearer ' + localStorage.getItem('token'); // 要保证取到

            if (!this.$isEmpty(this.$route.params.adv_position_id)) {
                this.info.ap_id = this.$route.params.adv_position_id;
            }

            // 判断是否是编辑
            if (!this.$isEmpty(this.$route.params.id)) {
                this.edit_id = this.$route.params.id;
            }

            if (this.edit_id > 0) {
                this.get_adv_info();
            }else{
               this.get_adv_list();
            }

        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>
    .line {
        text-align: center
    }
</style>